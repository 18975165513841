export var WithnoteBillType;
(function (WithnoteBillType) {
    WithnoteBillType["Expense"] = "expense";
    WithnoteBillType["Requisition"] = "requisition";
    WithnoteBillType["Receipt"] = "receipt";
})(WithnoteBillType || (WithnoteBillType = {}));
export var WithnoteSegmentType;
(function (WithnoteSegmentType) {
    WithnoteSegmentType[WithnoteSegmentType["WithnoteGroup"] = 0] = "WithnoteGroup";
    WithnoteSegmentType[WithnoteSegmentType["WithnoteDetail"] = 1] = "WithnoteDetail";
})(WithnoteSegmentType || (WithnoteSegmentType = {}));
export var WithnoteOrderByTimeField;
(function (WithnoteOrderByTimeField) {
    WithnoteOrderByTimeField["STARTTIME"] = "startTime";
    WithnoteOrderByTimeField["ENDTIME"] = "endTime";
    WithnoteOrderByTimeField["CreateTime"] = "createTime";
    WithnoteOrderByTimeField["FeeDate"] = "form.feeDate";
})(WithnoteOrderByTimeField || (WithnoteOrderByTimeField = {}));
export var WithnoteOrder;
(function (WithnoteOrder) {
    WithnoteOrder["DESC"] = "!";
    WithnoteOrder["ASC"] = "";
})(WithnoteOrder || (WithnoteOrder = {}));
export var WithnoteHidden;
(function (WithnoteHidden) {
    WithnoteHidden["SHOW"] = "show";
    WithnoteHidden["HIDDEN"] = "hidden";
})(WithnoteHidden || (WithnoteHidden = {}));
export var WithnoteDetailType;
(function (WithnoteDetailType) {
    WithnoteDetailType["Draft"] = "Draft";
    WithnoteDetailType["Pendding"] = "Pendding";
    WithnoteDetailType["Archived"] = "Archived";
    WithnoteDetailType["Put"] = "Put";
})(WithnoteDetailType || (WithnoteDetailType = {}));
export var createType;
(function (createType) {
    createType["E_BUSS_CARD"] = "E_BUSS_CARD";
})(createType || (createType = {}));
