import { __decorate, __extends, __metadata } from "tslib";
/**
 *  Created by pw on 2020/11/26 8:08 下午.
 */
import { attribute, Model } from 'mobx-cobweb';
import { Staff } from '@ekuaibao/collection-definition';
import { computed } from 'mobx';
import moment from 'moment';
import { MoneyMath } from '@ekuaibao/money-math';
var WithnoteGroup = /** @class */ (function (_super) {
    __extends(WithnoteGroup, _super);
    function WithnoteGroup() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(WithnoteGroup.prototype, "totalMoneys", {
        get: function () {
            var _a;
            var moneyMap = ((_a = this.relations) === null || _a === void 0 ? void 0 : _a.reduce(function (result, detial) {
                var money = result[detial.amount.standardNumCode] || {};
                result[detial.amount.standardNumCode] = new MoneyMath(money).add(detial.amount).__value;
                return result;
            }, {})) || {};
            return moneyMap ? Object.values(moneyMap) : [];
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(WithnoteGroup.prototype, "totalPending", {
        get: function () {
            var _a;
            return (_a = this.relations) === null || _a === void 0 ? void 0 : _a.filter(function (line) { return line.detailState === 'WAIT'; });
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(WithnoteGroup.prototype, "totalPendingMoneys", {
        get: function () {
            var _a;
            var moneyMap = (_a = this.totalPending) === null || _a === void 0 ? void 0 : _a.reduce(function (result, detial) {
                var money = result[detial.amount.standardNumCode] || {};
                result[detial.amount.standardNumCode] = new MoneyMath(money).add(detial.amount).__value;
                return result;
            }, {});
            return moneyMap ? Object.values(moneyMap) : [];
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(WithnoteGroup.prototype, "withnotePeriod", {
        get: function () {
            var format = i18n.get('MM月DD日');
            return this.createType === CreateType.E_BUSS_CARD ? '无限期' : this.startTime ? moment(this.startTime).format(format) + " ~ " + moment(this.endTime).format(format) : '';
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(WithnoteGroup.prototype, "withnoteSource", {
        get: function () {
            var format = i18n.get('MM月DD日');
            var createTypeStr = this.createType === CreateType.AUTO || CreateType.E_BUSS_CARD ? i18n.get('系统') : i18n.get('手动');
            debugger;
            return this.createTime
                ? "" + moment(this.createTime).format(format) + createTypeStr + i18n.get('创建')
                : null;
        },
        enumerable: false,
        configurable: true
    });
    var _a;
    WithnoteGroup.type = 'withnodes.group';
    WithnoteGroup.endpoint = '/api/withNotesGroup/v2';
    WithnoteGroup.enableStorage = true;
    __decorate([
        attribute({ isIdentifier: true }),
        __metadata("design:type", String)
    ], WithnoteGroup.prototype, "id", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", String)
    ], WithnoteGroup.prototype, "name", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", String)
    ], WithnoteGroup.prototype, "description", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", String)
    ], WithnoteGroup.prototype, "configId", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", Number)
    ], WithnoteGroup.prototype, "startTime", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", Number)
    ], WithnoteGroup.prototype, "endTime", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", String)
    ], WithnoteGroup.prototype, "createType", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", Boolean)
    ], WithnoteGroup.prototype, "hidden", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", Number)
    ], WithnoteGroup.prototype, "notesCount", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", Boolean)
    ], WithnoteGroup.prototype, "active", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", Number)
    ], WithnoteGroup.prototype, "createTime", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", String)
    ], WithnoteGroup.prototype, "corporationId", void 0);
    __decorate([
        attribute({ toOne: Staff }),
        __metadata("design:type", typeof (_a = typeof Staff !== "undefined" && Staff) === "function" ? _a : Object)
    ], WithnoteGroup.prototype, "staffId", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", Array)
    ], WithnoteGroup.prototype, "relations", void 0);
    __decorate([
        computed,
        __metadata("design:type", Array),
        __metadata("design:paramtypes", [])
    ], WithnoteGroup.prototype, "totalMoneys", null);
    __decorate([
        computed,
        __metadata("design:type", Array),
        __metadata("design:paramtypes", [])
    ], WithnoteGroup.prototype, "totalPending", null);
    __decorate([
        computed,
        __metadata("design:type", Array),
        __metadata("design:paramtypes", [])
    ], WithnoteGroup.prototype, "totalPendingMoneys", null);
    __decorate([
        computed,
        __metadata("design:type", String),
        __metadata("design:paramtypes", [])
    ], WithnoteGroup.prototype, "withnotePeriod", null);
    __decorate([
        computed,
        __metadata("design:type", String),
        __metadata("design:paramtypes", [])
    ], WithnoteGroup.prototype, "withnoteSource", null);
    return WithnoteGroup;
}(Model));
export default WithnoteGroup;
export var CreateType;
(function (CreateType) {
    /**
     * 手动
     */
    CreateType["MANUAL"] = "MANUAL";
    /**
     * 自动
     */
    CreateType["AUTO"] = "AUTO";
    CreateType["E_BUSS_CARD"] = "E_BUSS_CARD";
})(CreateType || (CreateType = {}));
